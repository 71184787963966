@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 1000px; /* Adjust this value to make the page narrower */
  margin: 0 auto;
  padding: 20px; /* Optional: adds some padding inside the container */
  background-color: #f4f4f4;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-custom {
  background-color: #f0f0f0;  /* Light gray background */
  color: #000;  /* Black text */
  margin-bottom: 16px;  /* Spacing from the bottom */
}

.btn-custom:hover {
  background-color: #e0e0e0;  /* Slightly darker gray on hover */
}

.btn-spacing {
  margin-right: 8px;  /* Add spacing between the buttons */
}
.search-input {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 25px;  /* Fully rounded corners */
  min-width: 300px;  /* Adjust width */
  height: 48px;
}

.magnifying-icon {
  color: #1976d2;  /* Adjust magnifying glass color */
}
.user-name {
  margin-right: 8px;
  color: #fff;
}
body {
  background-color: #f5f7fa;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('../public/background.jpg'); /* Add your image path here */
  background-size: cover; /* Adjust the size to cover the entire screen */
  background-attachment: fixed; /* Make the background fixed to create the parallax effect */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the background from repeating */
}

.app-bar {
  background-color: #1e1e1e;
}

.button-primary {
  background: linear-gradient(135deg, #6E45E2 0%, #88D3CE 100%);
  color: #fff;
  border-radius: 30px;
}
/* Container for the card grid */
.card-container {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: center; */
  padding: 16px;
}

/* event styles */
/* Card styles */
.event-card.MuiPaper-root.MuiCard-root{
  position: relative;
  height: 100%; /* Set the desired height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Prevent content from overflowing the card */
}

.event-card:hover {
  transform: scale(1.03);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.4);
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.event-title {
  font-size: 2rem;
  margin-right: 16px;
}

/* Typography for event details */
.event-info {
  color: #555;
}

/* Actions section in the card */
.card-actions {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

/* Button styles */
.button {
  background: linear-gradient(135deg, #6E45E2 0%, #88D3CE 100%);
  color: #fff; /* 强制文字颜色为白色 */
  padding: 8px 24px; /* 强制生效padding */
  text-transform: none !important; /* 强制不转换文本 */
  margin-right: 15px; /* 强制生效margin */
  border-radius: 12px; /* 强制生效圆角 */
  transition: background 0.3s ease-in-out;
}

.button:hover {
  background: linear-gradient(135deg, #88D3CE 0%, #6E45E2 100%); /* 强制生效背景渐变 */
}
/* No events message */
.no-events {
  width: 100%;
  text-align: left;
  color: #888;
  font-size: 18px;
}

/* Taskbar styling */
.nav-bar.MuiAppBar-root {
  background: linear-gradient(90deg, #3f51b5, #1e88e5);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.nav-button.MuiButton-root {
  margin: 0 10px;
  text-transform: none;
  font-size: 16px; 
  margin-right: 15px;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}
.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.category-button.MuiButton-root {
  text-transform: none;
  background: linear-gradient(90deg, #3f51b5, #1e88e5);
  color: #fff;
  font-size: 13px; 
  margin-right: 15px;
  border-radius: 12px;
}

.category-button:hover {
  background: linear-gradient(135deg, #6E45E2 0%, #88D3CE 100%);
}

.login-button.MuiButton-root {
  width: 45px;
  height: 45px;
  text-align: center;
  background-color: #ffffff;
  color: #1e88e5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.login-button:hover {
  transform: scale(1.1);
}

/* Hover effect for the menu items */
.MuiMenuItem-root:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.event-image-container {
  margin-top: 20px;
  text-align: center;
  position: relative;
  
}

.event-image {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
}

.arrow-button.MuiButton-root {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}
.background-image {
  background-image: url('../public/background.jpg');
  background-size: cover; /* Adjust the size as needed */
  background-position: center; /* Center the image */
  height: 100vh; /* Set height to cover the viewport */
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #0073b1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-info {
  font-size: 1.2em;
  color: #333;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #0073b1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-button.active {
  background-color: #0056b3;
  font-weight: bold;
}

.pagination-info {
  font-size: 1.2em;
  color: #333;
}
.contact-container.MuiContainer-root {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin-top: 32px;
  max-width: 600px;
  width: 60%;
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}

/* 隐藏时间轴部分 */
.rbc-time-content {
  display: none !important; /* 强制隐藏时间内容 */
}
/* 隐藏时间轴的时间段 */
.rbc-time-gutter {
  display: none !important;
}
/* 隐藏 week 和 day 视图中的时间头 */
.rbc-time-header-gutter {
  display: none !important; /* 强制隐藏顶部的时间显示部分 */
}

/* 将每一天的内容设置为全宽 */
.rbc-time-header-content {
  width: 100%;
}

/* 调整每个事件的样式，让它们更像列表 */
.rbc-event {
  display: block;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}
.custom-event {
  transition: transform 0.3s ease;  /* 添加动画过渡效果 */
}

.custom-event:hover {
  transform: scale(1.1);  /* 鼠标悬停时放大事件 */
  z-index: 2; /* 提高 z-index，让事件浮到前面 */
}
.custom-event-card {
  padding: 5px;
  background-color: #3174ad;
  color: white;
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑的放大动画 */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* 默认阴影 */
}

.custom-event-card:hover {
  transform: scale(1.01);  /* 鼠标悬停时，放大1.1倍 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* 放大时增加阴影 */
  z-index: 1000; /* 提升层级，确保事件浮在其他元素上方 */
}
.rbc-month-view {
  position: relative;
}

.rbc-day-bg {
  transition: background-color 0.1s ease; /* 平滑的背景色过渡 */
}

.rbc-day-bg:hover {
  background-color: #e0f7fa !important; /* Force hover color change */
}
.rbc-today {
  background-color: #dfe68b!important; 
}
/* 定义 agenda 视图中日期列的样式 */
.rbc-agenda-date-cell {
  position: relative;
  border-right: 1px solid #ccc; /* 添加右侧边框 */
  height: 100%; /* 确保边框高度覆盖整个列 */
}

/* 确保 agenda 列的高度适应多个 event */
.rbc-agenda-event-cell {
  height: auto;
  padding: 5px 10px;
}

/* 确保日期分割线覆盖整个all-day部分并延伸到底端 */
.rbc-allday-cell {
  height: 100% !important; /* 确保竖线覆盖整个all-day部分 */
  min-height: 100vh !important; /* 确保竖线的最小高度为100vh */
}
/* 覆盖日历组件的高度，确保布局稳定 */
.rbc-time-view {
  min-height: auto !important;
  height: auto !important;
}

/* 控制all-day部分的高度，使其全屏展示 */
.rbc-allday-row {
  height: auto !important;
  min-height: 100% !important;
}
  @media (max-width: 600px) {
    .contact-container.MuiContainer-root {
      background-color: white;
      padding: 16px;
      border-radius: 8px;
      margin-top: 32px;
      max-width: 600px;
      width: 100%;
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
    .rbc-calendar {
      width: 100% !important;
      overflow-x: auto;  /* 允许水平滚动以防止溢出 */
    }
  
    .rbc-time-content {
      flex-direction: column; /* 将日程表竖直排列 */
      height: auto !important;  /* 让高度自适应 */
    }
  
    .rbc-time-header, .rbc-time-header-content {
      display: block; /* 防止标题部分被压缩 */
    }
    .rbc-event {
      font-size: 12px;  /* 减小字体 */
      padding: 5px;  /* 减少内边距 */
    }
    .calendar-container {
      height: 100%;
      overflow: auto; /* 允许滚动 */
  }
  .nav-button {
    width: 50%;  
  }
  .event-title {
    font-size: 1.5rem;
    text-align: left;
  }

  .event-header {
    flex-direction: row;
    justify-content: space-between;
  }

  .event-share-btn {
    margin-left: auto;
  }
}